

export const RiskLevelMap: any = {
    LOW: "低风险",
    MEDIUM: "中等风险",
    HIGH: "高风险"
}
export const RiskLevelTagMap: any = {
    LOW: "success",
    MEDIUM: "warning",
    HIGH: "danger",
};


export const TagMap: any = {
    // WAITING: "default",
    PASS: "success",
    SYSTEM_AUTO_PASS: "success",
    REJECT: "danger",
    SYSTEM_AUTO_REJECT: "danger",
    EXPIRES: "info",
    IGNORE: "info",
    UNDETERMINED: "warning",
};
export const AuditStatusMap: any = {
    WAITING: "待审核",
    PASS: "通过",
    REVIEW: "疑似",
    REJECT: "拒绝",
    EXPIRES: "过期",
    IGNORE: "无需处理",
    UNDETERMINED: "待定",
    AGAIN_AUDITED:"已复审",
    SYSTEM_AUTO_PASS: "自动通过",
    SYSTEM_AUTO_REJECT: "自动拒绝",
}


export interface BlackWordItem {
    wordList: [string],
    className: string
}

export function getRouter(sourceType: string): string {
    switch (sourceType) {
        case "CHAPTER" || "章节内容" || "chapter":
            return "/audit/chapterContent";
        default:
            return "";
    }
}


export type ContentDataType = {
    againAuditorId: null;
    againAuditor: null;
    machineAuditStatus: string;
    sourceType: string;
    riskLevel: string;
    machineRejectReasonList: any;
    id: number;
    firstAuditorId: number;
    sourceId: string;
    personFirstAuditStatus: string;
    personAgainAuditStatus: string;
    personFirstTime: string;
    personAgainTime: null;
    machineTime: string;
    createTime: string;
    updateTime: string;
    extendDTO: extendDTOType;
    firstAuditor: firstAuditorType;
    content: contentType;
    rejectFirstReason: string;
    rejectAgainReason: string;
    loading: boolean;
};

export type contentType = {
    authorId: number;
    chapterTitle: string;
    chapterContent: string;
    auditContent:string
};

export type firstAuditorType = {
    id: number;
    nickname: string;
    mobile: string;
    email: string;
    avatarUrl: string;
    status: string;
    registerTime: string;
    createTime: string;
    updateTime: string;
};

export type extendDTOType = {
    bookId: number;
    bookName: string;
    chapterId: number;
    bookRejectCount: number;
    chapterRejectCount: number;
    chapterPublishTime: string;
    characterDTOList: never[];
};

